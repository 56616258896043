import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { createContext, useContext, useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../firebase-config";

/**
 * The DataContext provides the release information to all react components (see <DataContextProvider> tag in App())
 * The release data can then be accessed (see Releases.js)
 */

const dataContext = createContext();

export function DataContextProvider({ children }) {
	const [searchParams] = useSearchParams();
	const showAll = searchParams.get("show")
		? searchParams.get("show") === "all"
		: false;

	const [releases, setReleases] = useState([]);
	const [theme, setTheme] = useState(
		localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
	);
	const [osTheme, setOsTheme] = useState(null);
	const releasesLoaded = useRef(false);
	const releasesLoading = useRef(false);

	const DEFAULT_THEME = "light";

	function toggleTheme() {
		var currentTheme = theme;
		if (theme === "system") {
			currentTheme = osTheme;
		}
		console.log(currentTheme);
		if (currentTheme === "light") {
			setTheme("dark");
		} else {
			setTheme("light");
		}
	}

	const detectOSTheme = () => {
		if (!window.matchMedia) {
			// matchMedia method not supported
			setOsTheme(DEFAULT_THEME);
		}

		if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
			// OS theme setting detected as dark
			setOsTheme("dark");
		} else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
			setOsTheme("light");
		} else {
			setOsTheme(DEFAULT_THEME);
		}
	};

	async function refreshReleases() {
		if (releasesLoaded.current || releasesLoading.current) return;
		releasesLoading.current = true;

		// get releases collection
		const releaseCollectionRef = collection(db, "releases");
		const q = query(releaseCollectionRef, orderBy("date", "desc"));
		await getDocs(q)
			.then(async (querySnapshot) => {
				// combine id with data
				let releasesRaw = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				// filter only active
				let filteredReleases = releasesRaw.filter((release) => {
					return showAll || release.active;
				});
				// extract version information
				filteredReleases.forEach((release) => {
					release.versionArray = release.id.split(".");
					release.version = `${release.versionArray[0]}.${release.versionArray[1]}.${release.versionArray[2]}`;
				});
				// set data
				setReleases(filteredReleases);
				releasesLoaded.current = true;
				releasesLoading.current = false;
			})
			.catch((e) => {
				console.error("Error getting releases: ", e);
			});
	}

	const [brand, setBrand] = useState(undefined);
	const [brandData, setBrandData] = useState({});

	function toggleBrand() {
		var newBrand = localStorage.getItem("brand")
			? localStorage.getItem("brand")
			: "nimagna";
		if (newBrand === "nimagna") {
			localStorage.setItem("brand", "peoplefone");
			setBrand("peoplefone");
		} else {
			localStorage.setItem("brand", "nimagna");
			setBrand("nimagna");
		}
	}

	const detectBrand = () => {
		if (brand) return;

		// we check the current hostname if it includes the brand name
		const origin = window.location.origin;
		const knownBrands = ["nimagna", "peoplefone"];

		if (searchParams.get("brand")) {
			const brandParam = searchParams.get("brand");
			if (knownBrands.includes(brandParam)) {
				setBrand(brandParam);
				return;
			}
		}
		knownBrands.forEach((brand) => {
			if (origin.indexOf(brand) >= 0) {
				// if the brand name is in the hostname, we use this
				setBrand(brand);
				return;
			}
			// otherwise, we use the stored value or use Nimagna as default
			const stored = localStorage.getItem("brand")
				? localStorage.getItem("brand")
				: "nimagna";
			setBrand(stored);
		});
	};

	useEffect(() => {
		const brandingData = {
			nimagna: {
				baseName: "nimagna",
				companyName: "Nimagna AG",
				appName: "Nimagna",
				manifestFile: "manifest.json",
				favIcon: "favicon.ico",
				appIcon: "/images/nimagna_icon_new.svg",
				infoUrl: "https://www.nimagna.com",
				infoUrlName: "nimagna.com",
				infoPhone: "+41 (58) 255 25 30",
				infoEmail: "hello@nimagna.com",
			},
			peoplefone: {
				baseName: "peoplefone",
				companyName: "Peoplefone AG",
				appName: "peoplefone STUDIO",
				manifestFile: "studio_manifest.json",
				favIcon: "studio_favicon.ico",
				appIcon: "/images/peoplefoneStudioIcon.svg",
				infoUrl:
					"https://www.peoplefone.com/de-ch/kunden/produkte/kollaboration/peoplefone-studio",
				infoUrlName: "peoplefone.com",
				infoPhone: "+41 (xx) xxx xx xx",
				infoEmail: "support@peoplefone.ch",
			},
		};
		setBrandData(brandingData[brand]);
	}, [brand]);

	const [themeColors, setThemeColors] = useState({});

	useEffect(() => {
		localStorage.setItem("theme", theme);
		if (theme === "light" || (theme === "system" && osTheme === "light")) {
			setThemeColors({
				green1: "Green", // primary green
				green2: "LimeGreen", // secondary green
				navbar: "#fee", // navbar background
			});
		} else {
			setThemeColors({
				green1: "LimeGreen",
				green2: "Green",
				navbar: "#211", // navbar background
			});
		}
	}, [theme, setThemeColors, osTheme]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		refreshReleases();
		detectOSTheme();
		detectBrand();
	});

	useEffect(() => {
		if (theme === "system") {
			document.documentElement.setAttribute("data-theme", osTheme);
		} else {
			document.documentElement.setAttribute("data-theme", theme);
		}
	}, [theme, osTheme]);

	return (
		<dataContext.Provider
			value={{
				releases,
				refreshReleases,
				theme,
				toggleTheme,
				brand,
				brandData,
				toggleBrand,
				themeColors,
			}}
		>
			{children}
		</dataContext.Provider>
	);
}

export function useReleaseData() {
	return useContext(dataContext);
}
