import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "bulma/css/bulma.css";
import "./release-app.css";
import "./App.css";
import { Navigation } from "./components/Navigation";
import { Releases } from "./components/Releases";
import { Release } from "./components/Release";
import { Year } from "./components/Helpers";
import { DataContextProvider, useReleaseData } from "./context/DataContext";
import { useEffect } from "react";

/**
 * The main App creates the routing to show different elements based on the URL.
 * The main layout is defined in Layout() at the bottom.
 */

const router = createBrowserRouter([
	{
		// the general page layout
		element: <Layout />,
		children: [
			{ path: "/", element: <Releases /> },
			{ path: "releases", element: <Releases /> },
			{ path: "release/:version", element: <Release /> },
		],
	},
]);
export default function App() {
	return (
		<>
			<RouterProvider router={router} />
		</>
	);
}
function Layout() {
	return (
		<>
			<DataContextProvider>
				<MetaData />
				<Navigation />
				<div className="block" m="5">
					<Outlet />
				</div>
				<Footer />
			</DataContextProvider>
		</>
	);
}

function MetaData() {
	const { brandData } = useReleaseData();

	// effect to update favicon
	useEffect(() => {
		// favicon
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		link.href = `/${brandData?.favIcon}`;
		// title
		document.title = `${brandData?.appName} Releases`;
		let authorTag = document.querySelector("meta[name~='author']");
		authorTag.content = brandData?.companyName;
		let descriptionTag = document.querySelector("meta[name~='description']");
		descriptionTag.content = `Public releases of ${brandData?.appName}`;
		let manifestLink = document.querySelector("link[rel~='manifest']");
		manifestLink.href = `/${brandData?.manifestFile}`;
	}, [brandData]);

	return <></>;
}

function Footer() {
	const { brandData } = useReleaseData();
	return (
		<footer className="footer has-text-centered">
			This page provides information about public releases of{" "}
			{brandData?.appName}
			.
			<br />
			Visit <a href={brandData?.infoUrl}>{brandData?.infoUrlName}</a> for more
			information about {brandData?.appName}.
			<br />
			&copy; <Year /> by {brandData?.companyName}. All rights reserved.
		</footer>
	);
}
